<template>
  <section class="reels">
    <div class="wrapper">
      <h2 class="reels__title">{{ title }}</h2>
    </div>
    <div :class="{ wrapper: isLong }">
      <ClientOnly>
        <div class="reels__slider">
          <Swiper
            v-bind="swiperSettings"
            navigation
            pagination
            watch-slides-progress
            :modules="[Pagination, Navigation]"
          >
            <SwiperSlide
              v-for="(item, index) in media"
              :key="`reelse_${index}`"
              v-slot="{ isVisible }"
              class="reels__slider-slide video"
            >
              <UIPictureTag
                :is-visible="isVisible"
                :image-seo="item.imageSeo"
                :image-webp="`${url}${item.image.webp}`"
                :image-original="`${url}${item.image.source}`"
              />
              <div class="reels__slider-wrapper">
                <div class="reels__slider-content">
                  <div v-if="item.name" class="reels__slider-name">{{ item.name }}</div>
                  <div v-if="item.text" class="reels__slider-desc">
                    {{ item.text }}
                  </div>
                </div>
              </div>

              <UIButton @click="openMediaModal(index)">
                <NuxtIcon name="IconPlay" />
              </UIButton>
            </SwiperSlide>
          </Swiper>
        </div>
      </ClientOnly>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import './styles.scss';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';
import UIButton from '~/components/UI/button/UIButton.vue';
import { useRuntimeConfig, ref, useMediaModal, onUnmounted, computed } from '#imports';
import type { ImageItem, ImageSEO } from '~/types/pages';

const url = useRuntimeConfig().public.siteUrl;

interface Props {
  title: string;
  isLong: boolean;
  media: {
    name: string;
    text: string;
    image: ImageItem;
    imageSeo: ImageSEO;
    oid: number;
    id: number;
  }[];
}

const props = defineProps<Props>();

const swiperSettings = ref({
  autoHeight: false,
  spaceBetween: 16,
  slidesPerView: 1.5,
  centerInsufficientSlides: true,
  loop: props.media.length > 3,
  breakpoints: {
    425: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    520: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 5,
      spaceBetween: 24,
    },
    1100: {
      slidesPerView: 5,
      spaceBetween: 24,
    },
    1300: {
      slidesPerView: 6,
      spaceBetween: 24,
    },
    1600: {
      slidesPerView: 8,
      spaceBetween: 32,
    },
  },
});

const dataSource = computed(() =>
  props.media.map(item => {
    return {
      html: `<div class="reelse__frame"><iframe
             src="https://vk.com/video_ext.php?oid=${item.oid}&id=${item.id}&hd=2"
             allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0"
             allowfullscreen></iframe><div>`,
    };
  }),
);

const { destroyMediaModal, openMediaModal } = useMediaModal(dataSource.value);

onUnmounted(() => destroyMediaModal());
</script>

<style lang="scss"></style>
